body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiDrawer-modal .MuiDrawer-paperAnchorLeft {
  width:80%;
  background-color: #fff !important;
}

.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
    background-color: #005f9a !important;
}

.MuiButton-textPrimary {
color: #005f9a !important;
}

.MuiSnackbarContent-message {
  font-size: 12px !important;
  flex: 0 0 85% !important;
  display: flex !important;
}

.MuiSnackbarContent-root {
  display: flex !important;
  flex-direction: row !important;
}

.MuiSnackbarContent-message button{
  padding-right: 10px !important;
}
audio::-webkit-media-controls-panel {
  background-color: #7fd1dc;
}
.noConnection .MuiSnackbarContent-message {
  flex: 0 0 95% !important;
}
.snackbar-update .MuiSnackbarContent-message{
  flex: 0 0 60% !important;
  font-size: 15px !important;
}
.snackbar-update .MuiIconButton-sizeSmall {
  font-size: 15px;
  color: #00aabe;
  font-weight: bold;
}
@media (min-width: 600px) {
.MuiSnackbarContent-root {
    flex-grow: initial;
    min-width: 400px !important;
}
}
.MuiSnackbar-anchorOriginBottomCenter {
  bottom:80px !important;
}